/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react"
import { navigate } from "gatsby"

import { createClient } from "graphql-ws"
import { GraphQLWsLink } from "@apollo/client/link/subscriptions"
import { getMainDefinition } from "@apollo/client/utilities"
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  split,
} from "@apollo/client"
import { AppContextProvider } from "./src/context/appContext"
// gatsby-browser.js
import "@fontsource/roboto/100.css" // Weight 100 regular
import "@fontsource/roboto/100-italic.css" // Weight 100 italic
import "@fontsource/roboto/300.css" // Weight 300 regular
import "@fontsource/roboto/300-italic.css" // Weight 300 italic
import "@fontsource/roboto/400.css" // Weight 400 regular
import "@fontsource/roboto/400-italic.css" // Weight 400 italic
import "@fontsource/roboto/500.css" // Weight 500 regular
import "@fontsource/roboto/500-italic.css" // Weight 500 italic
import "@fontsource/roboto/700.css" // Weight 700 regular
import "@fontsource/roboto/700-italic.css" // Weight 700 italic
import "@fontsource/zcool-qingke-huangyou/400.css" // Default weight for ZCOOL QingKe HuangYou

const httpLink = new HttpLink({
  uri: process.env.GATSBY_EGPT_API_URL ?? "",
})

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.GATSBY_EGPT_WEBSOCKET_URL ?? "",
  })
)

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    )
  },
  wsLink,
  httpLink
)

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
})

export const onPreRouteUpdate = ({ location }) => {
  if (location.pathname === "/Techoversity/") {
    navigate("/techoversity")
  } else if (location.pathname === "/googlenext/") {
    // Redirect to another page
    navigate("/")
  } else if (
    location.pathname === "/aification-hackathon/" ||
    location.pathname === "/aification-hackathon" ||
    location.pathname === "/our-expertise/enterprise-cloud" ||
    location.pathname === "/our-expertise/enterprise-cloud/" ||
    location.pathname === "/co-create" ||
    location.pathname === "/co-create/" ||
    location.pathname === "/using-dsg" ||
    location.pathname === "/using-dsg/"
  ) {
    navigate("/book-demo")
  } else if (location.pathname.startsWith("/our-team")) {
    navigate("/the-dream-team")
  } else if (
    location.pathname === "/our-awards/" ||
    location.pathname === "/our-awards"
  ) {
    navigate("/?section=awards-recognition-section")
  } else if (
    location.pathname === "/our-purpose/" ||
    location.pathname === "/our-purpose" ||
    location.pathname === "/our-expertise" ||
    location.pathname === "/our-expertise/"
  ) {
    navigate("/")
  } else if (
    location.pathname === "/solutions/legacy-code-modernization-ai/" ||
    location.pathname === "/solutions/legacy-code-modernization-ai"
  ) {
    navigate("/solutions/developer-productivity-ai/")
  } else if (
    location.pathname ===
      "/solutions/inventory-and-quality-control-automation/" ||
    location.pathname === "/solutions/inventory-and-quality-control-automation"
  ) {
    navigate("/solutions/labor-automation-with-ai-robotics/")
  } else if (
    location.pathname === "/wow-digital-experience/" ||
    location.pathname === "/wow-digital-experience"
  ) {
    navigate("/digital-transformation-doesn-t-have-to-be-painful/")
  } else if (
    location.pathname === "/cloud-automation-services/" ||
    location.pathname === "/cloud-automation-services"
  ) {
    navigate("/solutions/cloud-foundation-and-data-platform-for-ai/")
  }

  if (location.pathname.startsWith("/post/")) {
    const newPath = location.pathname.replace("/post", "")
    navigate(newPath)
  } else if (
    location.pathname === "/robotics-edge-device-fabrication/" ||
    location.pathname === "/robotics-edge-device-fabrication"
  ) {
    navigate("/accelerators/robotics-and-edge-device-fabrication/")
  } else if (
    location.pathname === "/healthcare-industry/" ||
    location.pathname === "/healthcare-industry"
  ) {
    navigate("/ai-for-healthcare/")
  } else if (
    location.pathname === "/application-software-deployment-checklist//1000/" ||
    location.pathname === "/application-software-deployment-checklist//1000" ||
    location.pathname === "/application-software-deployment-checklist/1000/" ||
    location.pathname === "/application-software-deployment-checklist/1000"
  ) {
    navigate("/application-software-deployment-checklist/")
  } else if (
    location.pathname === "/managed-cloud-services" ||
    location.pathname === "/managed-cloud-services/"
  ) {
    navigate("/the-business-value-of-managed-cloud-services-explained/")
  } else if (
    location.pathname === "/government-services" ||
    location.pathname === "/government-services/"
  ) {
    navigate("/ai-for-government/")
  }
}

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>
    <AppContextProvider>{element}</AppContextProvider>
  </ApolloProvider>
)
