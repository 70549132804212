import React, { createContext, useReducer } from "react"

import { Toast } from "primereact/toast/toast"

import {
  Action,
  ActionType,
  AppContextType,
  State,
  // Polygon,
  // Point,
  // FeedbackImageData,
  // HandsPolygon,
  // ObjectsPolygon,
  // CroppedFeedbackImage,
} from "./interfacesAndTypes"

const initialState: State =
  // sessionStorage.getItem("ObjectCountData")
  // ? JSON.parse(sessionStorage.getItem("ObjectCountData") as string)
  // :
  {
    client_id: "",
    toast: null,
    showContactFormWindow: false,
    showChatWindow: false,
    showSideMenu: false,
    chatAiName: "timai",
    showAcceleratorFormWindow: false,
    showHamburgermenu: false,
  }

const reducer = (state: State, action: Action<ActionType> | any): State => {
  // const { contextStateKey, payload } = (action?.payload as 'contextStateKey' | 'payload') ?? {};

  switch (action.type) {
    case "SET_TOAST_REF": {
      return { ...state, toast: action.payload as Toast }
    }

    case "SET_CLIENT_ID": {
      return {
        ...state,
        client_id: action.payload.client_id as string,
      }
    }

    case "SET_SHOW_CONTACT_FORM_WINDOW": {
      return {
        ...state,
        showContactFormWindow: action.payload,
      }
    }

    case "SET_SHOW_CHAT_WINDOW": {
      return {
        ...state,
        showChatWindow: action.payload,
      }
    }

    case "SET_SHOW_SIDE_MENU": {
      return {
        ...state,
        showSideMenu: action.payload,
      }
    }

    case "SET_CHAT_AI_NAME": {
      return {
        ...state,
        chatAiName: action.payload,
      }
    }

    case "SET_SHOW_ACCELERATOR_FORM_WINDOW": {
      return {
        ...state,
        showAcceleratorFormWindow: action.payload,
      }
    }

    case "SET_SHOW_HAMBURGERMENU": {
      return {
        ...state,
        showHamburgermenu: action.payload,
      }
    }

    default:
      return state
  }
}

const AppContext = createContext<AppContextType>({
  state: initialState,
  dispatch: () => null,
  setClientId: () => null,
  showToast: () => null,
  setShowContactFormWindow: () => null,
  setShowChatWindow: () => null,
  setShowSideMenu: () => null,
  setChatAiName: () => null,
  setShowAcceleratorFormWindow: () => null,
  setShowHamburgermenu: () => null,
})

const AppContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const setClientId = (client_id: string) => {
    dispatch({
      type: "SET_CLIENT_ID",
      payload: { client_id },
    })
  }

  const showToast = (
    severity: "success" | "info" | "warn" | "error" | undefined,
    summary: "Success" | "Info" | "Warning" | "Error",
    detail: string,
    life?: number
  ) => {
    // state.toast?.clear();
    state.toast?.show({ severity, summary, detail, life })
  }

  const setShowContactFormWindow = (showContactFormWindow: boolean) => {
    dispatch({
      type: "SET_SHOW_CONTACT_FORM_WINDOW",
      payload: showContactFormWindow,
    })
  }

  const setShowChatWindow = (showChatWindow: boolean) => {
    dispatch({ type: "SET_SHOW_CHAT_WINDOW", payload: showChatWindow })
  }

  const setShowSideMenu = (showSideMenu: boolean) => {
    dispatch({ type: "SET_SHOW_SIDE_MENU", payload: showSideMenu })
  }

  const setChatAiName = (chatAiName: string) => {
    dispatch({ type: "SET_CHAT_AI_NAME", payload: chatAiName })
  }

  const setShowAcceleratorFormWindow = (showAcceleratorFormWindow: boolean) => {
    dispatch({
      type: "SET_SHOW_ACCELERATOR_FORM_WINDOW",
      payload: showAcceleratorFormWindow,
    })
  }

  const setShowHamburgermenu = (showHamburgermenu: boolean) => {
    dispatch({ type: "SET_SHOW_HAMBURGERMENU", payload: showHamburgermenu })
  }

  const contextValue: AppContextType = {
    state,
    dispatch,
    setClientId,
    showToast,
    setShowContactFormWindow,
    setShowChatWindow,
    setShowSideMenu,
    setChatAiName,
    setShowAcceleratorFormWindow,
    setShowHamburgermenu,
  }

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  )
}

const useAppContext = () => {
  const context = React.useContext<AppContextType>(AppContext)

  if (context === undefined) {
    throw new Error("useAppContext must be used within a AppContextProvider")
  }

  return context
}

export { AppContext, AppContextProvider, useAppContext }
